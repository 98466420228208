:has(aside[data-state="closed"]) main.dash {
  width: calc(100vw - 80px) !important;
}

main.dash {
  padding: 100px;
  width: calc(100vw - 360px);
  float: inline-end;
  height: 100vh;
  background: var(--backgroundColor);
  transition: width .2s ease-in-out;
  overflow: scroll;

  &:has(div#sheet) {
    padding: 0 !important;
  }

  @media screen and (max-width: 1400px) {
    padding: 40px;
  }

  @media screen and (max-width: 480px) {
    width: 100vw;
    padding: 10px;
    float: none;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  div.home {
    display: flex;
    align-items: center;
    justify-content: center;

    ul.seccions {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      li {
        width: 300px;
        max-height: 300px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: all .1s ease-in-out;

        &:hover:not(:has(span:where([dataType="INACTIVE"], [dataType="UNAVAILABLE"]))) {
          background: rgba(0, 0, 0, 0.2);
          transform: scale(1.05);

          a::after,
          a::before {
            transform: scale(1.05);
          }
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: inherit;
          width: inherit;
          padding: 20px;
          color: #2b2b2b;
          gap: 10px;

          &:not(:has(span:where([dataType="AVAILABLE"], [dataType="WIP"]))) {
            pointer-events: none;
            cursor: not-allowed;

            h2,
            p {
              color: #bbb;
            }
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 40px;
            box-shadow: 5px -5px 0 5px #fff;
            width: 20px;
            height: 20px;
            border-radius: 0 10px 0 0;
            transition: all .1s ease-in-out;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            right: 0;
            box-shadow: 5px -5px 0 5px #fff;
            width: 20px;
            height: 20px;
            border-radius: 0 10px 0 0;
            transition: all .1s ease-in-out;
          }

          span.status {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background: #fff;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            z-index: 1;
            box-shadow: 0 0 0 10px #fff;

            &[dataType="AVAILABLE"] {
              background: var(--green);
            }

            &[dataType="UNAVAILABLE"] {
              background: var(--green);
              filter: hue-rotate(220deg);

              svg path {
                stroke: color-mix(in srgb, var(--green) 100%, #333 50%);
              }
            }

            &[dataType="INACTIVE"] {
              background: #ccc;

              svg path {
                stroke: color-mix(in srgb, #ccc 100%, #333 50%);
              }
            }

            &[dataType="WIP"] {
              background: var(--green);
              filter: hue-rotate(260deg);

              svg path {
                stroke: color-mix(in srgb, var(--green) 100%, #333 50%);
              }
            }

            svg {
              width: 20px;
              height: 20px;
              margin: 5px;
            }
          }

          h2 {
            font-size: 2.4rem;
            text-transform: uppercase;
            font-weight: 800;
            width: calc(100% - 40px);
            height: 24px;
          }

          p {
            font-size: 1.6rem;
            width: 100%;
            text-wrap: balance;
          }
        }
      }
    }
  }
}

*::-webkit-scrollbar {
  display: none;
}