@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Concert+One&family=Edu+AU+VIC+WA+NT+Pre:wght@400..700&family=Josefin+Sans:wght@100&family=Playpen+Sans:wght@100..800&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap');

/* One UI 6.1 Emojis.ttf */

@font-face {
  font-family: "SamsungEmoji";
  src: url("assets/One UI 6.1 Emojis.ttf") format("truetype");
  unicode-range: U+1F000-1F6FF, U+1F700-1F77F, U+1F780-1F7FF,
    U+1F800-1F8FF, U+1F900-1F9FF, U+1FA00-1FA6F,
    U+1FA70-1FAFF, U+2600-26FF, U+2700-27BF,
    U+2300-23FF, U+2B50, U+1F004-1F0CF, U+2B06;
}

@font-face {
  font-family: "TwitterEmoji";
  src: url("assets/twemoji.ttf") format("truetype");
  unicode-range: U+1F000-1F6FF, U+1F700-1F77F, U+1F780-1F7FF,
    U+1F800-1F8FF, U+1F900-1F9FF, U+1FA00-1FA6F,
    U+1FA70-1FAFF, U+2600-26FF, U+2700-27BF,
    U+2300-23FF, U+2B50, U+1F004-1F0CF, U+2B06;
}

:root {
  --backgroundColor: #fff;

  --highEmphasisText: rgba(0, 0, 0, 0.87);
  --mediumEmphasisText: rgba(0, 0, 0, 0.6);
  --disabledText: rgba(0, 0, 0, 0.38);

  --contrastColor: 0, 0, 0;

  --green: #91D6AC;
  --green2: #95c59a;
  --red: #6a0000;

  --statusGreen: #57F287;
  --statusRed: #ED4245;

  --colorD: #82d882;
  --colorID: #d8d882;
  --colorC: #d88282;
  --colorI: #d882d8;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-display: swap;
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  min-height: 100svh;
  max-width: 100vw;
}

:focus-visible {
  border-radius: inherit;
  outline: 4px solid color-mix(in srgb, #111 10%, #fff 100%);
}

div.App:not(:has(header#header)) main.body {
  top: 0 !important;
}

main.body {
  top: 60px;
  min-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: inherit !important;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  line-height: 1.5;
}

* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  backface-visibility: hidden;

  *:not(section.fonts *):not(main.dash section.cartell div.preview.assaig *) {
    font-optical-sizing: auto;
    /* font-family: 'Josefin Sans', sans-serif; */
    font-family: "Baloo Bhaijaan 2", "TwitterEmoji", serif;
  }

  section.fonts,
  main.dash section.cartell div.preview.assaig {
    font-family: 'ITC Kabel' !important;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  --size: 9px;
  background-image: radial-gradient(#ffffff 30%, transparent 30%),
    radial-gradient(#fafafa 30%, transparent 30%);
  background-color: var(--red);
  background-position: 0 0, var(--size) var(--size);
  background-size: calc(var(--size) * 2) calc(var(--size) * 2);
}

*::-webkit-scrollbar-thumb {
  background-color: #1b1b1b;
  background-image: url("./assets/45-degree-fabric-light.png");
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.App {
  text-align: center;
  min-height: 100svh;
}