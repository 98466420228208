main.noticies {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: min(80%, 1400px);

	&:has(article.noticia) {
		flex-direction: row;
		gap: 20px;

		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			width: 100vw;
			padding: 20px;
			margin: 0;
		}
	}

	aside {
		display: flex;
		justify-content: flex-start;
		place-self: flex-start;
		align-items: flex-start;
		gap: 20px;
		flex-direction: column;
		padding: 0 20px;
		border-left: 2px solid #f0f0f0;

		@media (max-width: 768px) {
			border-left: none;
			border-top: 2px solid #f0f0f0;
			width: 100%;
			padding: 20px 0;

			ul li {
				max-width: 100% !important;
			}
		}

		h2 {
			font-size: 1.6rem;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content: center;
			gap: 20px;
			flex-direction: column;

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				flex-direction: row;
				width: 100%;
				max-width: 300px;
				border: 2px solid #f0f0f0;
				border-radius: 5px;
				transition: background 0.3s ease;

				&:hover {
					background: #f0f0f0;
				}

				a {
					text-decoration: none;
					color: #111;
					font-size: 1.6rem;
					font-weight: 600;
					text-wrap: balance;
					width: 100%;
					height: 100%;
					padding: 5px 10px;
				}
			}
		}
	}

	ul.noticies {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		flex-direction: row;

		li.noticia {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			flex-direction: column;
			border-radius: 5px;
			flex: 1 1 300px;

			a {
				height: 100%;
				width: 100%;
			}
		}
	}
}