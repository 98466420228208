section#puntuacio {
  table {
    tbody {
      tr {
        height: 20px;
        td {
          padding: 0;
          text-align: center;
          color: #111;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }
  }
}