div#loadingPage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - 60px);
	background-color: var(--backgroundColor);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 1.5rem;
	font-weight: bold;
	color: #333;

	.loading {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		border: 10px solid #333;
		border-top-color: transparent;
		animation: loading 1s infinite;
	}

	@keyframes loading {
		to {
			transform: rotate(360deg);
		}
	}
}