div#notfound {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 16rem;
    font-weight: 800;
    color: #111;
    margin-bottom: 20px;
    -webkit-text-stroke: 7px #111;
    filter: drop-shadow(0 0 0.2rem #111);
  }

  p {
    font-size: 1.6rem;
    font-weight: 700;
    color: #111;
    margin: 0;
    text-wrap: balance;
  }

  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: #111;
    margin: 0;
    text-decoration: none;
    border-bottom: 2px solid #111;
    padding: 0 10px;
    transition: background 0.15s;
    min-height: 2.4rem;
    text-wrap: balance;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 4px 4px 0 0;
    gap: 5px;

    svg {
      height: 2rem;
      width: 2rem;

      path {
        stroke: #111;
      }
    }

    &:hover {
      background: color-mix(in srgb, #111 10%, #f9f9f9 100%);
    }
  }
}