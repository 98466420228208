header#header {
  background: rgba($color: #fff, $alpha: 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 60px;
  z-index: 100;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);
  padding: 10px max(10%, calc(50vw - 700px));
  gap: 10px;

  a.home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: row;
    text-decoration: none;
    gap: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      flex-direction: row;
      flex-wrap: wrap;
      width: 88.73px;
      font-size: 1.6rem;
      gap: 0;

      span {
        font-size: 1.6rem;
        color: #333;
        line-height: 1.4rem;
        height: fit-content;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  &>nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 0px;

    @media screen and (max-width: 1300px) {
      display: none;
    }

    &>ul {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 60px;

      &>li {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        color: #111;
        font-size: 1.6rem;
        white-space: nowrap;
        height: 40px;
        gap: 4px;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;

        &:has(:not(a)) {
          padding: 0 20px;
        }

        &:has(ol.sections)::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 10px;
          display: block;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: -10px;
          width: calc(100% - 40px);
          cursor: normal;
          height: 4px;
          border-radius: 4px / 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background: var(--red);
          transform: scaleX(0);
          transition: all .1s ease-in-out;
        }

        &>a {
          text-decoration: none;
          color: inherit;
          height: 40px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 4px;
        }

        svg {
          width: 1.6rem;
          height: 1.6rem;

          path {
            stroke: #111;
          }
        }

        &:hover,
        &:has(ol.sections:hover) {
          background: rgba($color: #111, $alpha: .05);

          ol.sections {
            visibility: visible;
            opacity: 1;
            top: calc(100% + 10px);
          }
        }

        &.active {
          &::before {
            transform: scaleX(1);
          }
        }

        ol.sections {
          display: flex;
          visibility: hidden;
          opacity: 0;
          flex-direction: column;
          position: absolute;
          top: calc(100% + 20px);
          left: 0;
          align-items: center;
          justify-content: center;
          width: fit-content;
          padding: 10px;
          background: #fff;
          transition: all .1s ease-in-out;
          border-radius: 0 0 8px 8px;
          box-shadow: 0 0 10px rgba($color: #111, $alpha: .2);

          li {
            display: flex;
            width: 100%;
            border-radius: 5px;
            justify-content: center;
            transition: all .1s ease-in-out;

            a {
              display: flex;
              width: 100%;
              padding: 5px 10px;
              justify-content: flex-start;
              align-items: center;
              text-decoration: none;
              white-space: nowrap;
              color: #111;
              font-size: 1.6rem;
              font-weight: 500;
            }

            &:hover {
              background: rgba($color: #111, $alpha: .05);
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: -4px;
            left: 0;
            background: var(--red);
            width: 100%;
            border-radius: 4px / 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 4px;
          }
        }
      }
    }
  }

  ul.xxss {
    display: flex;
    align-items: center;
    height: 60px;
    flex-direction: row;
    gap: 5px;

    @media screen and (max-width: 1400px) {
      display: none;
    }

    li {
      height: 30px;
      width: 30px;
      display: flex;
      border-radius: 40%;
      cursor: pointer;
      transition: background .2s ease-in-out;
      background: transparent;

      &:hover {
        transform: scale(1.2);

        img {
          transform: scale(calc(1 / 1.2));
        }

        &.ig {
          background: color-mix(in srgb, #C13584 20%, #fff);
        }

        &.fb {
          background: color-mix(in srgb, #1877F2 20%, #fff);
        }

        &.x {
          background: color-mix(in srgb, #000 20%, #fff);
        }

        &.bs {
          background: color-mix(in srgb, #1083fe 20%, #fff);
        }

        &.yt {
          background: color-mix(in srgb, #FF0000 20%, #fff);
        }

        &.tw {
          background: color-mix(in srgb, #6441A4 20%, #fff);
        }

        &.tt {
          background: color-mix(in srgb, #FE2C55 20%, #fff);
        }
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
    }
  }

  div.openMenu {
    display: none;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    svg {
      width: 3.2rem;
      height: 3.2rem;

      path {
        stroke: #111;
      }
    }

    @media screen and (max-width: 1300px) {
      display: flex;
    }
  }

  aside#menu {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    background: rgba($color: #000000, $alpha: .3);
    transition: all .3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    padding: 20px 0;

    &[data-state="open"],
    &.open {
      opacity: 1;
      visibility: visible;

      nav {
        transform: translateX(0);
      }
    }

    ul.xxss {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      height: fit-content;
    }

    nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-height: calc(100vh - 40px - 60px);
      height: 100%;
      width: calc(100vw - 20px);
      max-width: 400px;
      padding: 20px 0 20px 20px;
      background: #fff;
      border-radius: 8px 0 0 8px;
      transition: all .3s ease-in-out;
      transform: translateX(100%);

      div.closeMenu {
        width: 50px;
        height: 50px;
        display: flex;
        position: absolute;
        top: 0px;
        background: #fff;
        left: -50px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px 0 0 8px;

        @media screen and (min-width: 500px) {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -8px;
            width: 8px;
            height: 100%;
            background: #fff;
          }

          &:before {
            content: "";
            position: absolute;
            top: 42px;
            right: -8px;
            width: 16px;
            height: 16px;
            background: transparent;
            border-top: 8px solid #fff;
            border-left: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid transparent;
            border-radius: 50%;
            transform: rotate(45deg);
          }
        }

        @media screen and (max-width: 500px) {
          top: -60px;
          left: 0;
          border-radius: 8px;
        }
      }

      ul.xxss {
        width: 100%;
      }

      ul.webSections {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        justify-content: center;
        height: 100%;
        width: 100%;

        li {
          background: rgba($color: #000000, $alpha: .1);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 40px;
          width: 100%;
          border-radius: 20px 0 0 20px;

          a,
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #333;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 10px;
            border-radius: 4px;
            width: 100%;
            transition: all .3s ease-in-out;
            cursor: pointer;
          }

          &:has(span.open) {
            ol.sections {
              display: flex;
            }
          }

          ol.sections {
            display: none;
            flex-direction: column;
            position: relative;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            padding: 4px;
            gap: 4px;

            li {
              display: flex;
              width: 100%;
              min-height: 30px;
              height: 30px;
              border-radius: 4px 0 0 4px;
              justify-content: center;

              &:last-child {
                border-radius: 4px 0 0 16px;
              }

              a {
                display: flex;
                width: 100%;
                padding: 10px;
                justify-content: flex-start;
                align-items: center;
                text-decoration: none;
                white-space: nowrap;
                color: #333;
                font-size: 1.6rem;
                font-weight: 500;
              }

              &:hover {
                background: rgba($color: #000000, $alpha: .1);
              }
            }
          }
        }
      }
    }
  }
}