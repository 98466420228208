div#form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  gap: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  h1 {
    font-size: 2.0rem;
    text-align: center;
    margin: 10px 0;
    text-transform: uppercase;
    width: 100%;
  }

  p {
    font-size: 1.6rem;
    text-align: center;
    margin: 10px 0;
    width: 100%;
  }

  div.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    gap: 10px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;

      label {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin: 10px 0;
        position: absolute;
        top: -15px;
        left: 10px;
        z-index: 1;
        background: #fff;
        border-radius: 5px;
        padding: 0 5px;
      }

      span.error {
        font-size: 1.6rem;
        color: red;
        margin: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: rgba($color: red, $alpha: .2);
        border-radius: 5px;
        padding: 5px;

        svg {
          width: 20px;
          height: 20px;

          path {
            stroke: red;
          }
        }
      }
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #000;
      border-radius: 5px;
      font-size: 1.6rem;
    }

    button {
      padding: 10px;
      border: 1px solid #000;
      border-radius: 5px;
      background-color: #000;
      color: #fff;
      cursor: pointer;
    }
  }

  div.message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    padding: 10px;
    width: 100%;
    border: 2px dashed #000;
    border-radius: 5px;
    height: 100px;

    p {
      font-size: 1.6rem;
    }
  }
}