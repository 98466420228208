div.dynamicLinks {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div.newDynamicLink {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    background-color: color-mix(in srgb, #111 3%, transparent);
    border-radius: 8px;

    div.options {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      position: relative;

      div[contentEditable] {
        font-size: 1.6rem;
        font-weight: 600;
        color: #111;
        display: inline-flex;
        width: auto;
        align-items: center;
        justify-content: flex-start;
        min-width: 100px;
        border: none;
        line-height: 1;
        border-radius: 5px;
        height: 24px;
        padding: 3px 10px 0;
        background: color-mix(in srgb, #111 10%, transparent);
      }
    }

    button.add {
      position: absolute;
      background: blue;
      width: 100%;
      height: 100%;
      border: none;
      left: 0;
      z-index: 1;
      border-radius: 8px;
      transition: all 0.1s ease;

      &:disabled {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
    table-layout: auto;

    thead {
      background-color: color-mix(in srgb, #111 10%, transparent);

      tr {
        th {
          padding: 10px;
          text-align: left;
          font-weight: 500;
          font-size: 1.2rem;
          text-transform: uppercase;
        }
      }
    }

    th:last-child,
    td:last-child {
      width: 1%;
    }

    tbody {
      tr {
        background-color: color-mix(in srgb, #111 1%, transparent);

        &:nth-child(2n) {
          background-color: color-mix(in srgb, #111 3%, transparent);
        }

        td {
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;
          text-align: left;
          font-size: 1.6rem;

          div.profile {
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 1.6rem;

            span {
              width: 20px;
              position: relative;
              height: 20px;
              border-radius: 50%;
              background-color: color-mix(in srgb, var(--color) 40%, transparent);
              overflow: hidden;

              &:not(:has(img)) {
                &:before {
                  content: "";
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  top: 3px;
                  left: 5px;
                  right: 5px;
                  border-radius: 50%;
                  display: flex;
                  background-color: color-mix(in srgb, var(--color) 100%, transparent);
                }

                &:after {
                  content: "";
                  position: absolute;
                  width: 20px;
                  height: 20px;
                  top: 12px;
                  left: 0;
                  right: 0;
                  border-radius: 40%;
                  transform: rotate(45deg);
                  display: flex;
                  background-color: color-mix(in srgb, var(--color) 100%, transparent);
                }
              }
            }
          }

          code {
            font-size: 1.6rem;
            font-weight: 600;
            color: #111;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            line-height: 1;
            border-radius: 5px;
            height: 24px;
            padding: 3px 10px 0;
            background: color-mix(in srgb, #111 10%, transparent);

            button.copy {
              border: none;
              background: transparent;
              cursor: pointer;
              font-size: 1.6rem;
              color: #111;
              position: absolute;
              right: -25px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;

              &:active {
                transform: scale(0.9) !important;
              }

              svg {
                width: 1.6rem;
                height: 1.6rem;
                transition: all 0.3s ease;

                path {
                  stroke: #111;
                  stroke-width: 2.5;
                }
              }
            }
          }

          div.accions {
            display: flex;
            gap: 5px;
            width: 100px;
            justify-content: flex-start;
            align-items: center;

            button {
              border: none;
              border-radius: 5px;
              font-size: 1.6rem;
              cursor: pointer;
              transition: all 0.15s ease;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              color: #fff;

              &:active {
                transform: scale(0.9) !important;
              }

              &:hover {
                border: 1px solid;
                transform: scale(1.1);
              }

              svg {
                width: 2.0rem;
                height: 2.0rem;
                transition: all 0.3s ease;
              }

              &.danger {
                border-color: #f44336;
                background-color: color-mix(in srgb, #f44336 15%, transparent);

                svg :is(circle, path) {
                  stroke: #f44336;
                }
              }

              &.success {
                border-color: #4caf50;
                background-color: color-mix(in srgb, #4caf50 15%, transparent);

                svg :is(circle, path) {
                  stroke: #4caf50;
                }
              }

              &.warning {
                border-color: #ff9800;
                background-color: color-mix(in srgb, #ff9800 15%, transparent);

                svg :is(circle, path) {
                  stroke: #ff9800;
                }
              }

              &.primary {
                border-color: #7b7d80;
                background-color: color-mix(in srgb, #7b7d80 15%, transparent);

                svg :is(circle, path) {
                  stroke: #7b7d80;
                }
              }
            }
          }

          div.tags {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            span {
              padding: 10px 10px;
              background-color: #f2f2f2;
              border-radius: 10px;
              font-size: 1.2rem;
              font-weight: 600;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              //border: 1px solid;
              text-transform: capitalize;
              user-select: none;
              box-sizing: border-box;

              &[data-type="junta"] {
                color: #4caf50;
                border-color: #4caf50;
                background: color-mix(in srgb, #4caf50 20%, transparent);
              }

              &[data-type="tecnica"] {
                color: #2196f3;
                border-color: #2196f3;
                background: color-mix(in srgb, #2196f3 20%, transparent);
              }

              &[data-type="admin"] {
                color: #ff9800;
                border-color: #ff9800;
                background: color-mix(in srgb, #ff9800 20%, transparent);
              }

              &[data-type="comunicacio"] {
                color: #6441a5;
                border-color: #6441a5;
                background: color-mix(in srgb, #6441a5 20%, transparent);
              }

              &[data-type="acces"] {
                color: #f44336;
                border-color: #f44336;
                background: color-mix(in srgb, #f44336 20%, transparent);
              }
            }
          }
        }
      }
    }
  }
}